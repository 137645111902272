import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import { useDriverDetails } from 'state/forms/driverDetails/action';
import { usePolicyState } from 'state/policy/state';
import AgreementCard from 'components/AgreementCard';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import PermanentDriverAgreementForm from 'components/Forms/PermanentDriverAgreement';
import Layout from 'components/Layout';
import replaceAddDriverPlaceholders from 'helpers/placeholders/addDriverPlaceholders';
import { useFormRedirect } from 'helpers/useFormRedirect';
import { DriverType } from 'types/forms';

type PermanentDriverAgreementContentProps = {
  data: PermanentDriverAgreementData;
};

type PermanentDriverAgreementPageProps = PermanentDriverAgreementContentProps & {
  location: Location;
};

const PermanentDriverAgreementPage: React.FC<PermanentDriverAgreementPageProps> = ({
  data,
  location,
}) => {
  useFormRedirect();
  const page = data.csAddDriverPermanentDriverAgreement;
  const { policy } = usePolicyState();
  const [driverDetails] = useDriverDetails();
  return (
    <FormPageWrapper
      location={location}
      title={page.title}
      formPageName="permanentDriverAgreement"
      driverType={DriverType.PERMANENT}>
      <Layout pageType="form" meta={{ title: page.title }}>
        <FormHeading text={page.heading} />
        <RichText html={page.subheading} />
        <AgreementCard
          agreement={replaceAddDriverPlaceholders(
            page.agreement,
            policy?.personalDetails,
            driverDetails
          )}
        />
        <PermanentDriverAgreementForm />
      </Layout>
    </FormPageWrapper>
  );
};

export default PermanentDriverAgreementPage;

type PermanentDriverAgreementData = {
  csAddDriverPermanentDriverAgreement: {
    title: string;
    heading: string;
    subheading: string;
    agreement: string;
  };
};

export const query = graphql`
  query {
    csAddDriverPermanentDriverAgreement {
      title
      heading
      subheading
      agreement
    }
  }
`;
