import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { navigate } from 'gatsby';
import React from 'react';
import { useErrorState } from 'state/error/actions';
import { Knockout } from 'state/error/state';
import { useAboutTheDriver } from 'state/forms/aboutTheDriver/action';
import { usePermanentDriverAgreement } from 'state/forms/permanentDriverAgreement/action';
import BooleanRadioInput from 'components/BooleanRadioInput';
import FormFooter from 'components/Forms/FormFooter';
import QuestionField from 'components/QuestionField';
import { trackFieldError } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import { DriverType } from 'types/forms';
import usePermanentDriverAgreementQuestions from './questions';
import usePermanentDriverAgreementRules from './validation';

const PermanentDriverAgreementForm: React.FC = () => {
  const [permanentDriverAgreement, updatePermanentDriverAgreement] = usePermanentDriverAgreement();
  const [aboutTheDriver] = useAboutTheDriver();
  const [, updateErrorState] = useErrorState();
  const questions = usePermanentDriverAgreementQuestions();
  const rules = usePermanentDriverAgreementRules();
  const { getError, validateOnSubmit } = useValidation(
    permanentDriverAgreement,
    rules,
    trackFieldError
  );

  const handleSuccess = async (): Promise<void> => {
    if (permanentDriverAgreement.acceptTerms) {
      navigate(getFormPageRoute(DriverType.PERMANENT, FormPage.Summary));
    } else {
      updateErrorState({
        hasErrored: true,
        knockout:
          aboutTheDriver.isHirer || aboutTheDriver.isNominee
            ? Knockout.PERMANENT_HIRER_DRIVER_REJECTED_AGREEMENT_TERMS
            : Knockout.PERMANENT_DRIVER_REJECTED_AGREEMENT_TERMS,
      });
    }
  };

  return (
    <form onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
      <QuestionField question={questions.acceptTerms} errorText={getError('acceptTerms')}>
        <BooleanRadioInput
          id="acceptTerms"
          value={permanentDriverAgreement.acceptTerms}
          onChange={(newValue) => {
            updatePermanentDriverAgreement({ acceptTerms: newValue });
          }}
        />
      </QuestionField>
      <FormFooter
        backButton={{
          onClick: () =>
            navigate(getFormPageRoute(DriverType.PERMANENT, FormPage.LicenceRestrictions)),
        }}
      />
    </form>
  );
};

export default PermanentDriverAgreementForm;
