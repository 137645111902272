import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import { Question } from 'types/forms';

export type PermanentDriverAgreementQuestions = {
  acceptTerms: Question;
};

type CsPermanentDriverAgreementQuestions = {
  csAddDriverPermanentDriverAgreementQuestions: {
    accept_terms: CsQuestion;
  };
};

const query = graphql`
  query {
    csAddDriverPermanentDriverAgreementQuestions {
      accept_terms {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
      }
    }
  }
`;

const useAgreementQuestions = (): PermanentDriverAgreementQuestions => {
  const csQuestions = useStaticQuery<CsPermanentDriverAgreementQuestions>(query);
  const { processQuestion } = useQuestionProcessor();

  return {
    acceptTerms: processQuestion(
      csQuestions.csAddDriverPermanentDriverAgreementQuestions.accept_terms
    ),
  };
};

export default useAgreementQuestions;
