import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsPermanentDriverAgreementErrorMessages = {
  csAddDriverPermanentDriverAgreementQuestions: {
    accept_terms: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csAddDriverPermanentDriverAgreementQuestions {
      accept_terms {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useAgreementRules = (): ValidationRules<{ acceptTerms: boolean | undefined }> => {
  const errorMessages = useStaticQuery<CsPermanentDriverAgreementErrorMessages>(query);

  return {
    acceptTerms: [
      required(
        errorMessages.csAddDriverPermanentDriverAgreementQuestions.accept_terms.error_messages
          .missing
      ),
    ],
  };
};

export default useAgreementRules;
